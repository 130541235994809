<template>
  <div class="home">
    <!-- FLOATING BUTTON -->
    <a href="#" class="float">
      <a href="https://wa.me/6281389918678?text=Halo%20Saya%20Ingin%20Bertanya%20Tentang%20HRN%20Autocare" class="float"
        target="_blank">
        <b-img center src="/img/whatsapp.svg" width="40px" height="40px" alt="Center image" class="my-float"></b-img>
      </a>
    </a>
    <!-- END FLOATING BUTTON -->
    <!-- BANNER SESSION -->
    <div class="jumbotron-blur2 mb-5">
      <div class="jumbo-content">
        <div class="content">
          <b-img src="/img/hrn logo.png" class="mb-4" fluid alt="Fluid image" width="200px" height="200px"></b-img><br>
          <p class="h1"><b>PAKET SERVIS</b></p>
        </div>
      </div>
    </div>
    <!-- END BANNER SESSION -->
    <!-- PAKET SERVIS -->
    <div class="container">
      <h2 class="m-5 text-light"><b>PROMO GRAND OPENING DISCOUNT UP TO 30% !!!</b></h2>
      <div class="row text-light">
        <!-- PAKET WARRIOR -->
        <div class="col-lg-4 mb">
          <b-img thumbnail fluid src="/img/paket/WARRIOR.png" alt="Image 1"></b-img>
          <p><del>Rp. 570.000</del></p>
          <p class="h5">Rp. 399.000</p>
          <p class="h4"><b>FREE</b></p>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan kaki 4 roda
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan AC
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Fogging
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Free scan
                </p>
              </div>
            </div>
          </div>
          <a href="https://api.whatsapp.com/send?phone=6281389918678&text=Hai%20HRN,%20saya%20mau%20pesan%20servis%20PAKET%20WARRIOR%20.%0D%0ASilahkan%20mengisi%20form%20dibawah%20untuk%20melakukan%20pendaftaran%20service%20mobil%20anda.%0D%0ANama%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ATanggal%20Booking%20%3A%0D%0AJam%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ANo%20Kendaraan%20%20%20%20%20%20%3A%0D%0ANomor%20Telepon%20%20%20%20%3A%0D%0AKeluhan%20Kendaraan%20%3A"
            target="_blank">
            <b-button variant="outline-warning mb-4">BOOK NOW</b-button>
          </a>
        </div>
        <!-- END PAKET WARRIOR -->
        <div class="col-12 d-lg-none d-block">
          <hr class="separator" />
        </div>
        <!-- PAKET MASTER -->
        <div class="col-lg-4">
          <b-img thumbnail fluid src="/img/paket/MASTER.png" alt="Image 1"></b-img>
          <p><del>Rp. 784.000</del></p>
          <p class="h5">Rp. 549.000</p>
          <p class="h4"><b>FREE</b></p>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan kaki 4 roda
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan AC
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Fogging
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Free scan
                </p>
              </div>
            </div>
          </div>
          <a href="https://api.whatsapp.com/send?phone=6281389918678&text=Hai%20HRN,%20saya%20mau%20pesan%20servis%20PAKET%20MASTER%20.%0D%0ASilahkan%20mengisi%20form%20dibawah%20untuk%20melakukan%20pendaftaran%20service%20mobil%20anda.%0D%0ANama%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ATanggal%20Booking%20%3A%0D%0AJam%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ANo%20Kendaraan%20%20%20%20%20%20%3A%0D%0ANomor%20Telepon%20%20%20%20%3A%0D%0AKeluhan%20Kendaraan%20%3A"
            target="_blank">
            <b-button variant="outline-warning">BOOK NOW</b-button>
          </a>
        </div>
        <!-- END PAKET MASTER -->
        <div class="col-12 d-lg-none d-block">
          <hr class="separator" />
        </div>
        <!-- PAKET GRANDMASTER -->
        <div class="col-lg-4">
          <b-img thumbnail fluid src="/img/paket/GRANDMASTER.png" alt="Image 1"></b-img>
          <p><del>Rp. 855.000</del></p>
          <p class="h5">Rp. 599.000</p>
          <p class="h4"><b>FREE</b></p>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan kaki 4 roda
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan AC
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Fogging
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Free scan
                </p>
              </div>
            </div>
          </div>
          <a href="https://api.whatsapp.com/send?phone=6281389918678&text=Hai%20HRN,%20saya%20mau%20pesan%20servis%20PAKET%20GRANDMASTER%20.%0D%0ASilahkan%20mengisi%20form%20dibawah%20untuk%20melakukan%20pendaftaran%20service%20mobil%20anda.%0D%0ANama%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ATanggal%20Booking%20%3A%0D%0AJam%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ANo%20Kendaraan%20%20%20%20%20%20%3A%0D%0ANomor%20Telepon%20%20%20%20%3A%0D%0AKeluhan%20Kendaraan%20%3A"
            target="_blank">
            <b-button variant="outline-warning">BOOK NOW</b-button>
          </a>
        </div>
        <!-- END PAKET GRANDMASTER -->
        <div class="col-12 d-lg-none d-block">
          <hr class="separator" />
        </div>

        <!-- PAKET EPIC -->
        <div class="col-lg-4">
          <b-img thumbnail fluid src="/img/paket/EPIC.png" alt="Image 1"></b-img>
          <p><del>Rp. 1.499.000</del></p>
          <p class="h5">Rp. 1.049.000</p>
          <p class="h4"><b>FREE</b></p>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan kaki 4 roda
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan AC
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Fogging
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Free scan
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Free cuci
                </p>
              </div>
            </div>
          </div>
          <a href="https://api.whatsapp.com/send?phone=6281389918678&text=Hai%20HRN,%20saya%20mau%20pesan%20servis%20PAKET%20EPIC%20.%0D%0ASilahkan%20mengisi%20form%20dibawah%20untuk%20melakukan%20pendaftaran%20service%20mobil%20anda.%0D%0ANama%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ATanggal%20Booking%20%3A%0D%0AJam%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ANo%20Kendaraan%20%20%20%20%20%20%3A%0D%0ANomor%20Telepon%20%20%20%20%3A%0D%0AKeluhan%20Kendaraan%20%3A"
            target="_blank">
            <b-button variant="outline-warning mb-5">BOOK NOW</b-button>
          </a>
        </div>
        <!-- END PAKET EPIC -->
        <div class="col-12 d-lg-none d-block">
          <hr class="separator" />
        </div>
        <!-- PAKET LEGEND -->
        <div class="col-lg-4">
          <b-img thumbnail fluid src="/img/paket/LEGEND.png" alt="Image 1"></b-img>
          <p><del>Rp. 1.712.000</del></p>
          <p class="h5">Rp. 1.199.000</p>
          <p class="h4"><b>FREE</b></p>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan kaki 4 roda
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan AC
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Fogging
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Free scan
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Free cuci
                </p>
              </div>
            </div>
          </div>
          <a href="https://api.whatsapp.com/send?phone=6281389918678&text=Hai%20HRN,%20saya%20mau%20pesan%20servis%20PAKET%20LEGEND%20.%0D%0ASilahkan%20mengisi%20form%20dibawah%20untuk%20melakukan%20pendaftaran%20service%20mobil%20anda.%0D%0ANama%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ATanggal%20Booking%20%3A%0D%0AJam%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ANo%20Kendaraan%20%20%20%20%20%20%3A%0D%0ANomor%20Telepon%20%20%20%20%3A%0D%0AKeluhan%20Kendaraan%20%3A"
            target="_blank">
            <b-button variant="outline-warning">BOOK NOW</b-button>
          </a>
        </div>
        <!-- END PAKET LEGEND -->
        <div class="col-12 d-lg-none d-block">
          <hr class="separator" />
        </div>
        <!-- PAKET MYTHIC -->
        <div class="col-lg-4">
          <b-img thumbnail fluid src="/img/paket/MYTHIC.png" alt="Image 1"></b-img>
          <p><del>Rp. 1.712.000</del></p>
          <p class="h5">Rp. 1.199.000</p>
          <p class="h4"><b>FREE</b></p>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan kaki 4 roda
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan AC
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Fogging
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Free scan
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Free cuci
                </p>
              </div>
            </div>
          </div>
          <a href="https://api.whatsapp.com/send?phone=6281389918678&text=Hai%20HRN,%20saya%20mau%20pesan%20servis%20PAKET%20MYTHIC%20.%0D%0ASilahkan%20mengisi%20form%20dibawah%20untuk%20melakukan%20pendaftaran%20service%20mobil%20anda.%0D%0ANama%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ATanggal%20Booking%20%3A%0D%0AJam%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ANo%20Kendaraan%20%20%20%20%20%20%3A%0D%0ANomor%20Telepon%20%20%20%20%3A%0D%0AKeluhan%20Kendaraan%20%3A"
            target="_blank">
            <b-button variant="outline-warning">BOOK NOW</b-button>
          </a>
        </div>
        <!-- END PAKET MYTHIC -->
        <div class="col-12 d-lg-none d-block">
          <hr class="separator" />
        </div>

        <!-- PAKET GLORY MANUAL -->
        <div class="col-lg-4">
          <b-img thumbnail fluid src="/img/paket/GLORY MANUAL.png" alt="Image 1"></b-img>
          <p><del>Rp. 3.284.000</del></p>
          <p class="h5">Rp. 2.299.000</p>
          <p class="h4"><b>FREE</b></p>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan kaki 4 roda
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan AC
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Fogging
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Free scan
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Free cuci
                </p>
              </div>
            </div>
          </div>
          <a href="https://api.whatsapp.com/send?phone=6281389918678&text=Hai%20HRN,%20saya%20mau%20pesan%20servis%20PAKET%20GLORY%20MANUAL%20.%0D%0ASilahkan%20mengisi%20form%20dibawah%20untuk%20melakukan%20pendaftaran%20service%20mobil%20anda.%0D%0ANama%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ATanggal%20Booking%20%3A%0D%0AJam%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ANo%20Kendaraan%20%20%20%20%20%20%3A%0D%0ANomor%20Telepon%20%20%20%20%3A%0D%0AKeluhan%20Kendaraan%20%3A"
            target="_blank">
            <b-button variant="outline-warning mb-4">BOOK NOW</b-button>
          </a>
        </div>
        <!-- END PAKET GLORY MANUAL -->
        <div class="col-12 d-lg-none d-block">
          <hr class="separator" />
        </div>
        <!-- PAKET GLORY MATIC -->
        <div class="col-lg-4">
          <b-img thumbnail fluid src="/img/paket/GLORY.png" alt="Image 1"></b-img>
          <p><del>Rp. 3.570.000</del></p>
          <p class="h5">Rp. 2.499.000</p>
          <p class="h4"><b>FREE</b></p>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan kaki 4 roda
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan AC
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Fogging
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Free scan
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Free cuci
                </p>
              </div>
            </div>
          </div>
          <a href="https://api.whatsapp.com/send?phone=6281389918678&text=Hai%20HRN,%20saya%20mau%20pesan%20servis%20PAKET%20GLORY%20MATIC%20.%0D%0ASilahkan%20mengisi%20form%20dibawah%20untuk%20melakukan%20pendaftaran%20service%20mobil%20anda.%0D%0ANama%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ATanggal%20Booking%20%3A%0D%0AJam%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ANo%20Kendaraan%20%20%20%20%20%20%3A%0D%0ANomor%20Telepon%20%20%20%20%3A%0D%0AKeluhan%20Kendaraan%20%3A"
            target="_blank">
            <b-button variant="outline-warning">BOOK NOW</b-button>
          </a>
        </div>
        <!-- END PAKET GLORY MATIC -->
        <div class="col-12 d-lg-none d-block">
          <hr class="separator" />
        </div>
        <!-- PAKET DIESEL -->
        <div class="col-lg-4">
          <b-img thumbnail fluid src="/img/paket/DIESEL.png" alt="Image 1"></b-img>
          <p><del>Rp. 535.000</del></p>
          <p class="h5">Rp. 375.000</p>
          <p class="h4"><b>FREE</b></p>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan kaki 4 roda
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan AC
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Fogging
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Free scan
                </p>
              </div>
            </div>
          </div>
          <a href="https://api.whatsapp.com/send?phone=6281389918678&text=Hai%20HRN,%20saya%20mau%20pesan%20servis%20PAKET%20DIESEL%20.%0D%0ASilahkan%20mengisi%20form%20dibawah%20untuk%20melakukan%20pendaftaran%20service%20mobil%20anda.%0D%0ANama%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ATanggal%20Booking%20%3A%0D%0AJam%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ANo%20Kendaraan%20%20%20%20%20%20%3A%0D%0ANomor%20Telepon%20%20%20%20%3A%0D%0AKeluhan%20Kendaraan%20%3A"
            target="_blank">
            <b-button variant="outline-warning">BOOK NOW</b-button>
          </a>
        </div>
        <!-- END PAKET DIESEL -->
        <div class="col-12 d-lg-none d-block">
          <hr class="separator" />
        </div>

        <!-- PAKET AC -->
        <div class="col-lg-4">
          <b-img thumbnail fluid src="/img/paket/PAKET AC.png" alt="Image 1"></b-img>
          <p><del>Rp.712.000</del></p>
          <p class="h5">Rp. 499.000</p>
          <p class="h4"><b>FREE</b></p>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan kaki 4 roda
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan AC
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Fogging
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Free scan
                </p>
              </div>
            </div>
          </div>
          <a href="https://api.whatsapp.com/send?phone=6281389918678&text=Hai%20HRN,%20saya%20mau%20pesan%20servis%20PAKET%20AC%20.%0D%0ASilahkan%20mengisi%20form%20dibawah%20untuk%20melakukan%20pendaftaran%20service%20mobil%20anda.%0D%0ANama%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ATanggal%20Booking%20%3A%0D%0AJam%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ANo%20Kendaraan%20%20%20%20%20%20%3A%0D%0ANomor%20Telepon%20%20%20%20%3A%0D%0AKeluhan%20Kendaraan%20%3A"
            target="_blank">
            <b-button variant="outline-warning mb-5">BOOK NOW</b-button>
          </a>
        </div>
        <!-- END PAKET AC -->
        <div class="col-12 d-lg-none d-block">
          <hr class="separator" />
        </div>
        <!-- PAKET REM -->
        <div class="col-lg-4">
          <b-img thumbnail fluid src="/img/paket/PAKET REM.png" alt="Image 1"></b-img>
          <p><del>Rp. 357.000</del></p>
          <p class="h5">Rp. 250.000</p>
          <p class="h4"><b>FREE</b></p>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan kaki 4 roda
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan AC
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Fogging
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Free scan
                </p>
              </div>
            </div>
          </div>
          <a href="https://api.whatsapp.com/send?phone=6281389918678&text=Hai%20HRN,%20saya%20mau%20pesan%20servis%20PAKET%20REM%20.%0D%0ASilahkan%20mengisi%20form%20dibawah%20untuk%20melakukan%20pendaftaran%20service%20mobil%20anda.%0D%0ANama%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ATanggal%20Booking%20%3A%0D%0AJam%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ANo%20Kendaraan%20%20%20%20%20%20%3A%0D%0ANomor%20Telepon%20%20%20%20%3A%0D%0AKeluhan%20Kendaraan%20%3A"
            target="_blank">
            <b-button variant="outline-warning">BOOK NOW</b-button>
          </a>
        </div>
        <!-- END PAKET REM -->
        <div class="col-12 d-lg-none d-block">
          <hr class="separator" />
        </div>
        <!-- PAKET OLI -->
        <div class="col-lg-4">
          <b-img thumbnail fluid src="/img/paket/PAKET OLI.png" alt="Image 1"></b-img>
          <div class="row">
            <div class="col-4">
              <p>Oli Shell HX 6</p>
              <p><del>Rp. 928.000</del></p>
              <p>Rp. 650.000</p>
            </div>
            <div class="col-4">
              <p>Oli Shell HX 7</p>
              <p><del>Rp. 1.000.000</del></p>
              <p>Rp. 700.000</p>
            </div>
            <div class="col-4">
              <p>Oli Shell HX 8</p>
              <p><del>Rp. 1.728.0000</del></p>
              <p>Rp. 825.000</p>
            </div>
          </div>
          <p class="h4"><b>FREE</b></p>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan kaki 4 roda
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan AC
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Fogging
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Free scan
                </p>
              </div>
            </div>
          </div>
          <a href="https://api.whatsapp.com/send?phone=6281389918678&text=Hai%20HRN,%20saya%20mau%20pesan%20servis%20PAKET%20OLI%20.%0D%0ASilahkan%20mengisi%20form%20dibawah%20untuk%20melakukan%20pendaftaran%20service%20mobil%20anda.%0D%0ANama%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ATanggal%20Booking%20%3A%0D%0AJam%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ANo%20Kendaraan%20%20%20%20%20%20%3A%0D%0ANomor%20Telepon%20%20%20%20%3A%0D%0AKeluhan%20Kendaraan%20%3A"
            target="_blank">
            <b-button variant="outline-warning">BOOK NOW</b-button>
          </a>
        </div>
        <!-- END PAKET OLI -->
        <div class="col-12 d-lg-none d-block">
          <hr class="separator" />
        </div>

        <!-- PAKET MANOL 1 -->
        <div class="col-lg-4 mb">
          <b-img thumbnail fluid src="/img/paket/MANOL 1.png" alt="Image 1"></b-img>
          <p><del>Rp. 857.000</del></p>
          <p class="h5">Rp. 600.000</p>
          <p class="h4"><b>FREE</b></p>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan kaki 4 roda
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan AC
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Fogging
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Free scan
                </p>
              </div>
            </div>
          </div>
          <a href="https://api.whatsapp.com/send?phone=6281389918678&text=Hai%20HRN,%20saya%20mau%20pesan%20servis%20PAKET%20MANOL 1%20.%0D%0ASilahkan%20mengisi%20form%20dibawah%20untuk%20melakukan%20pendaftaran%20service%20mobil%20anda.%0D%0ANama%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ATanggal%20Booking%20%3A%0D%0AJam%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ANo%20Kendaraan%20%20%20%20%20%20%3A%0D%0ANomor%20Telepon%20%20%20%20%3A%0D%0AKeluhan%20Kendaraan%20%3A"
            target="_blank">
            <b-button variant="outline-warning mb-4">BOOK NOW</b-button>
          </a>
        </div>
        <!-- END MANOL 1 -->
        <div class="col-12 d-lg-none d-block">
          <hr class="separator" />
        </div>
        <!-- PAKET MANOL 2 -->
        <div class="col-lg-4">
          <b-img thumbnail fluid src="/img/paket/MANOL 2.png" alt="Image 1"></b-img>
          <p><del>Rp. 1.142.000</del></p>
          <p class="h5">Rp. 800.000</p>
          <p class="h4"><b>FREE</b></p>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan kaki 4 roda
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan AC
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Fogging
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Free scan
                </p>
              </div>
            </div>
          </div>
          <a href="https://api.whatsapp.com/send?phone=6281389918678&text=Hai%20HRN,%20saya%20mau%20pesan%20servis%20PAKET%20MANOL 2%20.%0D%0ASilahkan%20mengisi%20form%20dibawah%20untuk%20melakukan%20pendaftaran%20service%20mobil%20anda.%0D%0ANama%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ATanggal%20Booking%20%3A%0D%0AJam%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ANo%20Kendaraan%20%20%20%20%20%20%3A%0D%0ANomor%20Telepon%20%20%20%20%3A%0D%0AKeluhan%20Kendaraan%20%3A"
            target="_blank">
            <b-button variant="outline-warning">BOOK NOW</b-button>
          </a>
        </div>
        <!-- END PAKET MANOL 2 -->
        <div class="col-12 d-lg-none d-block">
          <hr class="separator" />
        </div>
        <!-- PAKET MANOL 3 -->
        <div class="col-lg-4">
          <b-img thumbnail fluid src="/img/paket/MANOL 3.png" alt="Image 1"></b-img>
          <p><del>Rp. 1.642.000</del></p>
          <p class="h5">Rp. 1.150.000</p>
          <p class="h4"><b>FREE</b></p>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan kaki 4 roda
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan AC
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Fogging
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Free scan
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Free cuci
                </p>
              </div>
            </div>
          </div>
          <a href="https://api.whatsapp.com/send?phone=6281389918678&text=Hai%20HRN,%20saya%20mau%20pesan%20servis%20PAKET%20MANOL 3%20.%0D%0ASilahkan%20mengisi%20form%20dibawah%20untuk%20melakukan%20pendaftaran%20service%20mobil%20anda.%0D%0ANama%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ATanggal%20Booking%20%3A%0D%0AJam%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ANo%20Kendaraan%20%20%20%20%20%20%3A%0D%0ANomor%20Telepon%20%20%20%20%3A%0D%0AKeluhan%20Kendaraan%20%3A"
            target="_blank">
            <b-button variant="outline-warning">BOOK NOW</b-button>
          </a>
        </div>
        <!-- END PAKET MANOL 3 -->
        <div class="col-12 d-lg-none d-block">
          <hr class="separator" />
        </div>

        <!-- PAKET MANOL 4 -->
        <div class="col-lg-4">
          <b-img thumbnail fluid src="/img/paket/MANOL 4.png" alt="Image 1"></b-img>
          <p><del>Rp. 3.357.000</del></p>
          <p class="h5">Rp. 2.350.000</p>
          <p class="h4"><b>FREE</b></p>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan kaki 4 roda
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan AC
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Fogging
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Free scan
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Free cuci
                </p>
              </div>
            </div>
          </div>
          <a href="https://api.whatsapp.com/send?phone=6281389918678&text=Hai%20HRN,%20saya%20mau%20pesan%20servis%20PAKET%20MANOL 4%20.%0D%0ASilahkan%20mengisi%20form%20dibawah%20untuk%20melakukan%20pendaftaran%20service%20mobil%20anda.%0D%0ANama%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ATanggal%20Booking%20%3A%0D%0AJam%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ANo%20Kendaraan%20%20%20%20%20%20%3A%0D%0ANomor%20Telepon%20%20%20%20%3A%0D%0AKeluhan%20Kendaraan%20%3A"
            target="_blank">
            <b-button variant="outline-warning">BOOK NOW</b-button>
          </a>
        </div>
        <!-- END PAKET MANOL 4 -->
        <div class="col-12 d-lg-none d-block">
          <hr class="separator" />
        </div>
        <!-- PAKET MANOL 5 -->
        <div class="col-lg-4">
          <b-img thumbnail fluid src="/img/paket/MANOL 5.png" alt="Image 1"></b-img>
          <p><del>Rp. 3.357.000</del></p>
          <p class="h5">Rp. 2.350.000</p>
          <p class="h4"><b>FREE</b></p>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan kaki 4 roda
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Pengecekan AC
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Fogging
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Free scan
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="d-flex justify-content-center">
                <img src="/img/check2.svg" alt="" width="20px" height="20px" class="mr-2">
                <p class="m-0 p-0">
                  Free cuci
                </p>
              </div>
            </div>
          </div>
          <a href="https://api.whatsapp.com/send?phone=6281389918678&text=Hai%20HRN,%20saya%20mau%20pesan%20servis%20PAKET%20MANOL 5%20.%0D%0ASilahkan%20mengisi%20form%20dibawah%20untuk%20melakukan%20pendaftaran%20service%20mobil%20anda.%0D%0ANama%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ATanggal%20Booking%20%3A%0D%0AJam%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3A%0D%0ANo%20Kendaraan%20%20%20%20%20%20%3A%0D%0ANomor%20Telepon%20%20%20%20%3A%0D%0AKeluhan%20Kendaraan%20%3A"
            target="_blank">
            <b-button variant="outline-warning">BOOK NOW</b-button>
          </a>
        </div>
        <!-- END PAKET MANOL 5 -->
        <div class="col-12 d-lg-none d-block">
          <hr class="separator" />
        </div>
      </div>
    </div>
    <!-- END PAKET SERVIS -->
    <!-- FOOTER -->
    <hr>
    <div class="container text-light">
      <div class="row mt-5 mb-5">
        <div class="col-lg-4">
          <p class="text-left"><b>WORKSHOP</b></p>
          <p class="text-left"><a class="workshop" href="https://goo.gl/maps/H55BkPURVjNNeesY9" target="_blank">Jl. Raya
              Parung - Ciputat No.88, RT.01/RW.01, Bojongsari Baru, Kec. Bojongsari, Kota Depok, Jawa Barat 16517</a>
          </p>
        </div>
        <div class="col-lg-4">
          <p class="text-left"><b>JAM BUKA</b></p>
          <p class="text-left">Setiap hari<br> 09:00 - 18:00 WIB</p>
        </div>
        <div class="col-lg-4">
          <p class="text-left"><b>CONTACT US</b></p>
          <p class="text-left"><a class="workshop" href="https://www.instagram.com/hrnautocare/" target="_blank">
              <div class="row">
                <div class="col-1 float-left">
                  <b-img center src="/img/instagram.svg" width="20px" height="20px" alt="Center image"></b-img>
                </div>
                <div class="col-11 float-right">
                  hrnautocare
                </div>
              </div>
            </a>
          </p>
          <p class="text-left"><a class="workshop" href="mailto:admin@hrnautocare.com" target="_blank">
              <div class="row">
                <div class="col-1">
                  <b-img center src="/img/email.svg" width="20px" height="20px" alt="Center image"></b-img>
                </div>
                <div class="col-11">
                  admin@hrnautocare.com
                </div>
              </div>
            </a>
          </p>
          <p class="text-left"><a class="workshop"
              href="https://wa.me/6281389918678?text=Halo%20Saya%20Ingin%20Bertanya%20Tentang%20HRN%20Autocare"
              target="_blank">
              <div class="row">
                <div class="col-1">
                  <b-img center src="/img/whatsapp.svg" width="20px" height="20px" alt="Center image"></b-img>
                </div>
                <div class="col-11">
                  081389918678
                </div>
              </div>
            </a>
          </p>
        </div>
      </div>
    </div>
    <hr class="footer-1">
    <p class="text-center text-light"><b>Copyright © 2022 HRN Auto Care All Rights Reserved</b></p>
    <!-- END FOOOTER -->
  </div>

</template>

<style>
  .float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25D366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
    z-index: 1000;
  }

  .my-float {
    margin-top: 10px;
    margin-left: 3px;
  }

  .jumbotron {
    background-color: #222222 !important;
    margin-bottom: 0 !important
  }

  a.workshop {
    color: #ffffff;
  }

  hr.separator {
    border: 2px solid grey;
  }

  hr.footer-1 {
    border: 1px solid grey;
  }

  .mechanic {
    width: 100vw;
    height: 40vh;
    position: relative;
    z-index: 3;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .jumbotron-blur2 {
    position: relative;
    width: 100vw;
    height: 65vh;
    overflow: hidden;
  }

  .jumbotron-blur2 .jumbo-content {
    position: relative;
    z-index: 2;
    color: #ffffff;
    top: 25vh;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .jumbotron-blur2:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("/img/banner paket service.png");
    background-size: cover;
    background-repeat: no-repeat;
    /* Set a specific height */
    min-height: 500px;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    /* filter: blur(5.5px);
    transform: scale(1.1); */
  }
</style>

<script>
  export default {
    name: 'HomeView',
    components: {

    },
    mounted() {
      window.scrollTo(0, 0)
    }
  }
</script>